import { format } from 'date-fns';
import { IEvent } from 'interfaces/common.interface';

export const DropdownLoaderTypes = {
  Skeleton: 'Skeleton',
  Default: 'Default',
};

export const getCalendarTooltip = (start: Date, _end: Date, event: IEvent) => {
  return `
    <div style="font-family: Montserrat, sans-serif; background-color: white; font-weight: 400; border-radius: 10px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); width: 300px; overflow: hidden; min-width: max-content;">
      <div style="background-color: #fce4d6; font-weight: 500; padding: 10px; display: flex; flex-direction: column; gap: 4;">
        <span style="font-size: 18px;">
          ${event.text}
        </span>
        <span style="color: #666;">
          ${format(start, 'eeee, MMMM d')}
        </span> 
      </div>
      <div style="padding: 16px 10px; display: flex; flex-direction: column; gap: 10px;">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span style="display: flex; align-items: center;">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            <span><span style="font-weight: 500;">Student Name:</span> ${event.students?.map((student) => student.name).join(', ') ?? 'Unassigned'}</span>
          </span>
          <span style="width: 10px; height: 10px; background-color: ${(event.students?.length && event.students[0].color) ?? '#d35400'}; border-radius: 50%;"></span>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span style="display: flex; align-items: center;">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span><span style="font-weight: 500;">Teacher Name:</span> ${event.teacher ?? 'Unassigned'}</span>
          </span>
          <span style="width: 10px; height: 10px; background-color: ${event.teacherColor ?? '#27ae60'}; border-radius: 50%;"></span>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span style="display: flex; align-items: center;">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M12 8v4l3 3"></path>
            </svg>
            <span><span style="font-weight: 500;">Location:</span> ${event.classroom}</span>
          </span>
          <span style="width: 10px; height: 10px; background-color: ${event.classroomColor ?? '#e74c3c'}; border-radius: 50%;"></span>
        </div>
        <div style="display: flex; align-items: center;">
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </svg>
          <span><span style="font-weight: 500;">Course:</span> ${event.course}</span>
        </div>
        <div style="display: flex; align-items: center;">
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
          <span><span style="font-weight: 500;">Session:</span> ${event.sessionNumber ?? 5} of ${event.totalSessions ?? 30}</span>
        </div>
      </div>
    </div>`;
};
