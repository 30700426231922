export const PUBLIC_NAVIGATION = Object.freeze({
  login: '/auth/login',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/auth/resetPassword/:token',
});

export const PRIVATE_NAVIGATION = Object.freeze({
  dashboard: {
    path: '/',
    title: 'Dashboard',
  },
  scheduleGeneration: {
    path: '/schedule_generation',
    title: 'Run Scheduler',
  },
  dataValidation: {
    path: '/data_validation',
    title: 'Data Validation',
  },
  syncLogs: {
    path: '/sync-logs',
    title: 'Sync Logs',
  },
  scheduleLogs: {
    path: '/schedule-logs',
    title: 'Schedule Logs',
  },
  calendar: {
    path: '/calendar',
    title: 'Scheduler Preview',
  },
});
