import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';

type instituteType = {
  allInstitutes: {
    id: number;
    name: string;
  }[];
  currentInstitute: number | null;
  processId: string;
};

const initialState: instituteType = {
  allInstitutes: [],
  currentInstitute: null,
  processId: '',
};

export const slice = createSlice({
  name: 'institutes',
  initialState,
  reducers: {
    setInstitutes: (
      state: instituteType,
      action: PayloadAction<{ id: number; name: string }[]>
    ) => {
      state.allInstitutes = action.payload;
      // state.currentInstitute = initialState.currentInstitute;
    },
    setCurrentInstitute: (state: instituteType, action: PayloadAction<number>) => {
      state.currentInstitute = action.payload;
    },
    setProcessId: (state: instituteType, action: PayloadAction<string>) => {
      state.processId = action.payload;
    },
  },
});

export const { reducer } = slice;

export const instituteSelector = (state: RootStateType) => {
  return state.institutes.allInstitutes;
};

export const currentInstituteSelector = (state: RootStateType) => {
  return state.institutes.currentInstitute;
};

export const processIdSelector = (state: RootStateType) => {
  return state.institutes.processId;
};

export const { setInstitutes, setCurrentInstitute, setProcessId } = slice.actions;

export default slice;
