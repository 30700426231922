import Header from 'components/Layout/components/Header';

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 p-0">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
