import Button from 'components/Button/Button';
import ReactSelect from 'components/FormElement/ReactSelect';
import { Option } from 'components/FormElement/types';
import BrightmontIcon from 'components/Icon/assets/BrightmontIcon';
import GreaterThanIcon from 'components/Icon/assets/GreaterThanIcon';
import LogoutIcon from 'components/Icon/assets/LogoutIcon';
import UserIcon2 from 'components/Icon/assets/UserIcon2';
import Image from 'components/Image';
import Modal from 'components/Modal';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { useAxiosGet } from 'hooks/useAxios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getAuth } from 'reduxStore/slices/authSlice';
import {
  instituteSelector,
  setCurrentInstitute,
  setInstitutes,
  setProcessId,
} from 'reduxStore/slices/instituteSlice';
import { setLoaderState } from 'reduxStore/slices/loaderSlice';
import { clearToken } from 'reduxStore/slices/tokenSlice';

const navItems = [
  {
    path: PRIVATE_NAVIGATION.scheduleGeneration.path,
    title: PRIVATE_NAVIGATION.scheduleGeneration.title,
  },
  {
    path: PRIVATE_NAVIGATION.dataValidation.path,
    title: PRIVATE_NAVIGATION.dataValidation.title,
  },
  {
    path: PRIVATE_NAVIGATION.calendar.path,
    title: PRIVATE_NAVIGATION.calendar.title,
  },
];

export default function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [institute, setInstitute] = useState<Option>({ value: '', label: '' });
  const { user } = useSelector(getAuth);
  const institutes = useSelector(instituteSelector);
  const [callApi, { isLoading }] = useAxiosGet();
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const getOptions = async (
    search: string,
    _loadedOptions: unknown,
    pagination?: { page: number }
  ) => {
    const response = await callApi('/institute', {
      params: {
        page: pagination?.page,
        limit: 10,
        ...(search && { search }),
      },
    });

    const { data } = response;

    if (data?.currentPage === 1 && data?.responseData?.length > 0) {
      const { id, name } = data.responseData[0];
      setInstitute({ value: id, label: name });
      dispatch(setCurrentInstitute(id));
      dispatch(setInstitutes(data.responseData));
    } else {
      dispatch(setInstitutes([...institutes, ...data.responseData]));
    }
    return {
      hasMore: data?.currentPage < data?.totalPages,
      options: data?.responseData?.map((item: { id: number; name: string }) => {
        return { value: item.id, label: item.name };
      }),
      additional: {
        page: !pagination ? 1 : pagination.page + 1,
      },
    };
  };

  const isActive = (path: string) => {
    const paths = Object.values(PRIVATE_NAVIGATION).map((nav) => nav.path);
    const currentIndex = paths.indexOf(window.location.pathname);
    const targetIndex = paths.indexOf(path);
    return targetIndex <= currentIndex;
  };

  useEffect(() => {
    dispatch(setLoaderState(true));
    const getProcessId = async () => {
      const response = await callApi(
        `/schedule-process-logs/last-run-data/${institute.value}`
      );
      if (response.error) {
        dispatch(setProcessId(''));
      } else {
        dispatch(setProcessId(response.data));
        dispatch(setLoaderState(false));
      }
    };
    if (institute.value) getProcessId();
  }, [institute]);
  return (
    <header className="flex items-center justify-between py-2 bg-primaryLight-20 shadow-sm px-10">
      <Modal modalEl={modalRef} setIsOpen={setIsOpen} />

      <nav className="flex gap-[20px] items-center text-grayText">
        <BrightmontIcon />
        {navItems.map((navItem, index) => (
          <React.Fragment key={navItem.path}>
            <NavLink
              to={navItem.path}
              className={
                isActive(navItem.path)
                  ? 'text-xl text-primary font-medium hover:text-primary hover:underline'
                  : 'text-xl font-light hover:text-primary hover:underline'
              }
            >
              {navItem.title}
            </NavLink>
            {index < navItems.length - 1 && <GreaterThanIcon />}
          </React.Fragment>
        ))}
      </nav>
      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          <ReactSelect
            className="bg-white text-grayText mr-2 rounded-lg text-lg"
            selectedValue={institute}
            onChange={(option) => {
              setInstitute(option as Option);
              dispatch(setCurrentInstitute((option as { value: number }).value));
            }}
            isPaginated
            loadOptions={getOptions}
            disabled={isLoading}
          />
        </div>
        <Button
          className="bg-white rounded-full overflow-hidden text-2xl font-semibold"
          onClickHandler={() => setIsOpen(true)}
        >
          <Image src="/images/profile.jpeg" alt="" width={40} height={40} />
        </Button>
        <div
          className={`flex flex-col absolute top-[4.3rem] right-8 bg-white text-black rounded-md z-10 shadow-lg border gap-2 px-2 py-2 ${
            !isOpen && 'hidden'
          }`}
          ref={modalRef}
        >
          <span className="flex items-center gap-1">
            <UserIcon2 />{' '}
            {user?.first_name
              ? `${user.first_name.slice(0, 1).toUpperCase()}${user.first_name.slice(1)} ${user.last_name?.slice(0, 1).toUpperCase()}${user.last_name?.slice(1)}`
              : 'Profile'}
          </span>
          <Button
            onClickHandler={() => {
              dispatch(clearToken());
            }}
            className="text-red-500 hover:bg-red-500 hover:text-white rounded-md flex text-lg items-center"
          >
            <LogoutIcon className="mr-2" /> Logout
          </Button>
        </div>
      </div>
    </header>
  );
}
