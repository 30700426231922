import { REACT_APP_API_URL } from 'config';
import { useAxiosGet } from 'hooks/useAxios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentInstituteSelector,
  setProcessId,
} from 'reduxStore/slices/instituteSlice';
import { setLoaderState } from 'reduxStore/slices/loaderSlice';
import { setSocket, socketSelector } from 'reduxStore/slices/socketSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';
import openSocket from 'socket.io-client';

const SocketComponent = () => {
  const dispatch = useDispatch();
  const [callApi] = useAxiosGet();
  const currentInstitute = useSelector(currentInstituteSelector);
  const socket = useSelector(socketSelector);
  const token = useSelector(getAuthToken);

  const handleCompleteProcessOfScheduleGeneration = async (data: string) => {
    if (currentInstitute) {
      const { status, instituteId } = JSON.parse(data);
      if (status === 'pending' && instituteId === currentInstitute) {
        dispatch(setLoaderState(true));
      } else {
        const response = await callApi(
          `/schedule-process-logs/last-run-data/${currentInstitute}`
        );
        if (response.error) {
          dispatch(setProcessId(''));
        } else {
          dispatch(setProcessId(response.data));
        }
        dispatch(setLoaderState(false));
      }
    }
  };

  useEffect(() => {
    socket?.on(
      'complete_process_of_schedule_generation',
      handleCompleteProcessOfScheduleGeneration
    );

    return () => {
      socket?.off(
        'complete_process_of_schedule_generation',
        handleCompleteProcessOfScheduleGeneration
      );
    };
  }, [currentInstitute, socket]);

  const connectSocket = async () => {
    const url = REACT_APP_API_URL;
    const socket = openSocket(url as string, {
      forceNew: true,
      transports: ['websocket'],
      query: {
        token,
      },
    });
    dispatch(setSocket(socket));
  };

  useEffect(() => {
    if (token) {
      connectSocket();
    }
  }, []);

  return <></>;
};
export default SocketComponent;
