import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';

export interface ILoader {
  isLoading: boolean;
}

const initialState: ILoader = {
  isLoading: false,
};

const slice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setLoaderState(state: ILoader, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { setLoaderState } = slice.actions;

export const getLoaderState = (state: RootStateType) => state.loaderSlice.isLoading;

export default slice;
