import * as Yup from 'yup';

// Login Validation

export const LoginValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid Email')
      .max(255, 'Maximum Character Validation')
      .required('email is Required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid Email'),
    password: Yup.string()
      .required('password is Required')
      .min(8, 'minimum 8 character Length Required')
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])\w+/,
        'upper And Lower Case Alphabets are required'
      )
      .matches(/\d/, 'number is Required')
      .matches(
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        'special Character is Required'
      ),
  });
};

// Forgot Password Validation

export const ForgotPasswordValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid Email')
      .max(255, 'Maximum Character Validation')
      .required('Email is Required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid Email'),
  });
};

export const ResetPasswordValidationSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .required('password is Required')
      .min(8, 'minimum 8 character Length Required')
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])\w+/,
        'upper And Lower Case Alphabets are required'
      )
      .matches(/\d/, 'number is Required')
      .matches(
        /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        'special Character is Required'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Password and confirm password must match'),
  });
};
